import React from "react"
import styled from "styled-components"
import { rem, transparentize, fluidRange } from "polished"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { usePagination } from "react-use-pagination"

import SEO from "../containers/seo"
import HiddenHeading from "../components/hidden-heading"
import _Layout, { Main } from "../containers/layout"
import _CaseStudy from "../components/cards/case-study"
import _Filters from "../components/links/case-studies-filters"
import { Image } from "../components/cards/img-card"
import _Button from "../components/buttons/button"
import { createFilters } from "../utils/misc"

export default function Videos({ data, pageContext: { type } }) {
  const { endIndex, nextEnabled, setNextPage } = usePagination({
    totalItems: data.videos.edges.length,
    initialPageSize: 6,
  })

  return (
    <Layout>
      <SEO title="Videos" />
      <Filters
        type={type}
        path="/videos"
        allText="All Videos"
        filters={createFilters(type, data)}
      />
      <HiddenHeading>Video blog</HiddenHeading>
      <Content>
        {data.videos.edges
          .slice(0, endIndex + 1)
          .map(({ node: { title, thumbnail, slug, category, tags } }) => (
            <Video
              key={slug}
              image={<Img fluid={thumbnail.localFile.childImageSharp.fluid} />}
              title={title}
              url={`/video/${slug}#content`}
              category={{
                text: category.text,
                url: `/videos/category/${category.url}`,
              }}
              tags={tags.map(({ id, text, url }) => ({
                id,
                text,
                url: `/videos/tag/${url}`,
              }))}
            />
          ))}
      </Content>
      {nextEnabled && <LoadMoreButton onClick={setNextPage} />}
    </Layout>
  )
}

const Video = styled(_CaseStudy)`
  justify-self: center;
  width: 100%;
`
const Filters = styled(_Filters)`
  grid-column: full;
  justify-self: center;
  width: 100%;
  padding: ${rem("10px")} 0;
  border: 1px solid ${props => transparentize(0.8, props.theme.color.dark)};
  border-left-width: 0;
  border-right-width: 0;
`
const LoadMoreButton = styled(_Button).attrs({ children: "load more" })`
  max-width: ${rem("179px")};
  justify-self: center;
  grid-column: main;
`
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column: main;
  gap: ${rem("20px")};

  @media screen and (min-width: ${rem("700px")}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${rem("900px")}) {
    grid-template-columns: repeat(3, 1fr);
  }

  ${Image} {
    padding-top: 56.25%;
  }
`
const Layout = styled(_Layout)`
  ${Main} {
    padding-top: ${rem("30px")};

    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "65px",
        toSize: "115px",
      },
      "400px",
      "1200px",
    )}
  }
`

export const pageQuery = graphql`
  query($filter: ContentfulVideoFilterInput) {
    videos: allContentfulVideo(
      sort: { fields: [createdAt, featured], order: [DESC, DESC] }
      filter: $filter
    ) {
      edges {
        node {
          title
          slug
          thumbnail {
            localFile {
              childImageSharp {
                fluid(maxWidth: 650, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          category {
            text
            url
          }
          tags {
            id
            text
            url
          }
        }
      }
    }
    categories: allContentfulVideo {
      distinct(field: category___text)
    }
    categorySlugs: allContentfulVideo {
      distinct(field: category___url)
    }
    tags: allContentfulVideo {
      distinct(field: tags___text)
    }
    tagSlugs: allContentfulVideo {
      distinct(field: tags___url)
    }
  }
`
